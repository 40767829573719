import React from 'react'
import Helmet from 'react-helmet'

import Layout from "../../components/layout"
import BgImage from '../../components/bg-image'

const Hoists = () => (
  <Layout>
    <Helmet title="Pool" />
    <BgImage image="/assets/outdoor-living.jpg" className="top">
      <h1>Hoists and Aids</h1>
    </BgImage>
    <section className="content">
      <p>
        When you want to enjoy your pool but need a little bit of help to be
        able to do so, various hoists and other aids can be installed to help
        those who have difficulty accessing their swimming pool safely. We have
        experience with the following aids to help you enjoy your pool as much
        as you can.
      </p>
      <ul>
        <li>Lifting Seat</li>
        <li>Sling Hoist</li>
        <li>Wheelchair Hoist</li>
        <li>Support Aid</li>
        <li>Lifting Aid</li>
      </ul>
    </section>
  </Layout>
)

export default Hoists
